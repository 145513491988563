<template>
  <div>
    <GenericTable
      ref="lista"
      name="setor"
      title="setores"
      add-route="setorAdicionar"
      edit-route="setorEditar"
      :filters="filters"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="setorRoute"
      :permissionsToWrite="['rw_unidade']"
    >
      <template #filters>
        <!-- Filtro de nome do setor -->
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome</label>
              <b-form-input
                v-model="filters.nome"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Filtro de descricao do setor -->
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Filtro de unidade do setor -->
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <GenericSelect
                name="unidadeSetor"
                route="unidade"
                labelKey="nome"
                v-model="filters.id_unidade"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelect from '../../components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      setorRoute: 'setor',
      filters: {
        nome: null,
        descricao: null,
        id_unidade: null,
      },
    };
  },
};
</script>
